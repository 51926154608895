import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
const VideoCall = () => {
  const [localStream, setLocalStream] = useState(null);
  const [socketId, setSocketId] = useState('Not connected');
  const [status, setStatus] = useState('Initializing...');
  const [targetId, setTargetId] = useState('');
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const peerConnection = useRef(null);
  const socket = useRef(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const configuration = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
  };
  useEffect(() => {
    // Connect to socket server
    socket.current = io('https://testapp.lodngo.com/');
    socket.current.on('connect', () => {
      setSocketId(socket.current.id);
      setStatus('Connected to server');
    });
    socket.current.on('connect_error', (error) => {
      setStatus(`Connection error: ${error.message}`);
    });
    socket.current.on('incomingCall', async ({ callerId }) => {
      if (window.confirm(`Incoming call from ${callerId}. Accept?`)) {
        await setupCall();
        socket.current.emit('acceptCall', { callerId });
      }
    });
    socket.current.on('ice-candidate', async ({ candidate }) => {
      try {
        if (peerConnection.current) {
          await peerConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
        }
      } catch (e) {
        console.error('Error adding received ice candidate', e);
      }
    });
    socket.current.on('offer', async ({ offer, from }) => {
      try {
        await setupCall();
        await peerConnection.current.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peerConnection.current.createAnswer();
        await peerConnection.current.setLocalDescription(answer);
        socket.current.emit('answer', { answer, target: from });
      } catch (e) {
        console.error('Error handling offer', e);
      }
    });
    socket.current.on('answer', async ({ answer }) => {
      try {
        await peerConnection.current.setRemoteDescription(new RTCSessionDescription(answer));
      } catch (e) {
        console.error('Error handling answer', e);
      }
    });
    // Cleanup on unmount
    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
      if (localStream) {
        localStream.getTracks().forEach(track => track.stop());
      }
      if (peerConnection.current) {
        peerConnection.current.close();
      }
    };
  }, []);
  const setupCall = async () => {
    setStatus('Setting up call...');
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true
      });
      setLocalStream(stream);
      localVideoRef.current.srcObject = stream;
      peerConnection.current = new RTCPeerConnection(configuration);
      stream.getTracks().forEach(track => {
        peerConnection.current.addTrack(track, stream);
      });
      peerConnection.current.ontrack = event => {
        remoteVideoRef.current.srcObject = event.streams[0];
      };
      peerConnection.current.onicecandidate = event => {
        if (event.candidate) {
          socket.current.emit('ice-candidate', {
            candidate: event.candidate,
            target: targetId
          });
        }
      };
      peerConnection.current.oniceconnectionstatechange = () => {
        setStatus(`ICE Connection State: ${peerConnection.current.iceConnectionState}`);
      };
      setStatus('Call setup complete');
    } catch (e) {
      setStatus(`Error setting up call: ${e.message}`);
      console.error('Error setting up call:', e);
    }
  };
  const startCall = async () => {
    if (!targetId) {
      alert('Please enter a target ID');
      return;
    }
    try {
      await setupCall();
      const offer = await peerConnection.current.createOffer();
      await peerConnection.current.setLocalDescription(offer);
      socket.current.emit('offer', { offer, target: targetId });
    } catch (e) {
      setStatus(`Error starting call: ${e.message}`);
      console.error('Error starting call:', e);
    }
  };
  const toggleAudio = () => {
    if (localStream) {
      const audioTrack = localStream.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setIsAudioMuted(!audioTrack.enabled);
    }
  };
  const toggleVideo = () => {
    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      setIsVideoOff(!videoTrack.enabled);
    }
  };
  const endCall = () => {
    if (peerConnection.current) {
      peerConnection.current.close();
    }
    if (localStream) {
      localStream.getTracks().forEach(track => track.stop());
      setLocalStream(null);
    }
    localVideoRef.current.srcObject = null;
    remoteVideoRef.current.srcObject = null;
    setStatus('Call ended');
  };
  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white p-6">
      <div className="max-w-6xl mx-auto w-full">
        <h1 className="text-3xl font-bold mb-4">Video Call Room</h1>
        <div className="mb-6">
          <p>Your ID: <span className="font-mono bg-gray-800 px-2 py-1 rounded">{socketId}</span></p>
        </div>
        <div className="flex gap-4 mb-6">
          <input
            type="text"
            value={targetId}
            onChange={(e) => setTargetId(e.target.value)}
            placeholder="Enter target ID"
            className="bg-gray-800 text-white px-4 py-2 rounded flex-grow"
          />
          <button
            onClick={startCall}
            className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded"
          >
            Start Call
          </button>
        </div>
        <div className="bg-gray-800 p-4 rounded mb-6">
          <p>{status}</p>
        </div>
        <div className="flex gap-4 mb-6">
          <button
            onClick={toggleAudio}
            className={`px-6 py-2 rounded ${
              isAudioMuted ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {isAudioMuted ? 'Unmute' : 'Mute'}
          </button>
          <button
            onClick={toggleVideo}
            className={`px-6 py-2 rounded ${
              isVideoOff ? 'bg-red-500 hover:bg-red-600' : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {isVideoOff ? 'Turn On Video' : 'Turn Off Video'}
          </button>
          <button
            onClick={endCall}
            className="bg-red-500 hover:bg-red-600 px-6 py-2 rounded"
          >
            End Call
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-gray-800 rounded-lg overflow-hidden aspect-video">
            <video
              ref={localVideoRef}
              autoPlay
              playsInline
              muted
              className="w-full h-full object-cover"
            />
          </div>
          <div className="bg-gray-800 rounded-lg overflow-hidden aspect-video">
            <video
              ref={remoteVideoRef}
              autoPlay
              playsInline
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideoCall;